#deliveryOptions {
  input[readonly] {
    cursor: pointer;
  }

  .help-bubble {
    margin-left: 5px;
    top: 1px;
  }

  .notification-sound-container {
    .container-heading {
      margin-right: 3px;
    }

    .slds-visual-picker_vertical {
      width: 95%;

      .slds-visual-picker__figure {
        height: auto;
        width: auto;
      }
    }
  }

  .asset-sounds-container {
    .container-heading {
	  margin-bottom: 0px;	
    }
    .asset-sound-container {
	  margin-top: 10px;
    }
  }

  .interactive-notification-container {
    margin-top: 30px;

    .slds-combobox_container {
      width: 400px;
    }

    .notification-buttons-container {
      margin-top: 10px;

      .container-heading {
        margin-bottom: 0px;
      }
    }
  }

  .asset-interactive-buttons-container {

	.container-heading {
		margin-top: 10px;
		margin-bottom: 0;	
	}
	.asset-interactive-buttons-heading {
		margin-top: 30px;
		display: block;
	}

	.container-helper-text {
		text-transform: none;
	}
  }

  .app-template-category-container {
    margin-top: 30px;
    .no-category {
    	font-style: italic;
    }
  }

  .ios-badge-toggle-container {
    margin-top: 30px;

    .slds-checkbox--off,
    .slds-checkbox--on {
      text-align: center;
    }
  }

  .send-window-container,
  .push-copy-to-inbox-container {
    margin-top: 30px;

    .slds-checkbox--off,
    .slds-checkbox--on {
      text-align: center;
    }

    .window-picker {
      #startTime {
        width: 129px;
        margin-right: 10px;
      }

      #endTime {
        width: 129px;
      }
    }

    .timezone-picker {
      .slds-form-element {
        display: inline-block;
        width: 90%;
      }
    }

    .slds-input {
      /* Override slds-visual-picker's input styling */
      width: 100%;
      position: relative;
      height: initial;
      border: 1px solid #dddbda;
      clip: initial;
      overflow: initial;
      margin: 0;
      padding: 0 1rem 0 0.75rem;
    }
  }

  .push-copy-to-inbox-container {
    .date-time-picker {
      margin-bottom: 5px;

      input {
        &::placeholder {
          color: $default-heading-color;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: $default-heading-color;
        }

        &::-ms-input-placholder {
          color: $default-heading-color;
        }
      }
    }

    .timezone-picker {
      .slds-form-element {
        display: inline-block;
        width: 100%;
      }

      input {
        &::placeholder {
          color: $default-heading-color;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: $default-heading-color;
        }

        &::-ms-input-placholder {
          color: $default-heading-color;
        }
      }

      .desc {
        color: $default-subheading-color;
        font-size: $default-label-font-size;
        margin-top: 0.125rem;
      }
    }

    .slds-input {
      /* Override slds-visual-picker's input styling */
      width: 100%;
      position: relative;
      height: initial;
      border: 1px solid #dddbda;
      clip: initial;
      overflow: initial;
      margin: 0;
      padding: 0 1rem 0 0.75rem;
    }

    #timezone-dropdown-listbox {

      /* Override slds-visual-picker's span tag styling */
      .slds-media {
        display: flex;

        .slds-media__figure {
          display: inline-block;
        }

        .slds-media__body {
          display: inline-block;
        }
      }
    }
  }

  .slds-visual-picker {
    .slds-input {
      position: relative;
      width: 100%;
      border: 1px solid #dddbda;

      &:active,
      &:focus {
        border-color: #0070d2;
        box-shadow: 0 0 3px #0070d2;
      }
    }

    .slds-listbox {
      .slds-media {
        display: flex;
      }
    }
  }

  .campaign-container {
    margin-top: 30px;

    .selected-campaign {
      .slds-pill__label {
        margin-left: 0.25rem;
        font-size: 12px;
      }

      .btn-change-campaign {
        margin-left: 10px;
      }
    }
  }
}

#campaign-selector {
  height: 100%;
  margin-top: 20px;

  .slds-card {
    height: calc(100% - 52px);
    box-shadow: none;
    border-radius: 0px;

    .slds-card__body {
      height: calc(100% - 56px) !important;
    }
  }
}
