.mobilepush-push-actvity {
	.scm-common {
		.scm-common-header {
			.scm-common-header-icon {
				svg {
					path.st0 {
						fill: #4DC6BD;
					}
				}
			}
		}
	}

	#summary-hub-spokes {
		.slds-card {
			.scm-card-icon {
				svg {
					height: 20px;
					width: 20px;

					path.st0 {
						fill: black;
					}
				}
			}
		}

		.hub-spoke {
			.slds-grid {
				.slds-col:first-child {
					padding-right: 0px;
				}
			}
		}

		.scm-metadata {
			.scm-metadata-item_value {
				#mobile-app-icon {
					width: 20px;
					height: 20px;
					margin-top: -1px;
					margin-right: 5px;
				}

				#mobile-app-name {
					vertical-align: middle;
				}
			}
		}
	}
}
