.message-configuration {
    .slds-combobox_container {
        width: 300px;
    }

    .slds-icon_container {
        &.slds-icon-utility-check {
            color: #0070d2;
        }
    }

    .disabled-text {
        color: #999999;
    }
}

[data-view="messageConfig"],
#messageConfiguration {
    .expired {
        color: #c23934;
        text-transform: uppercase;
    }
}

.test-sends-button {
  padding-top: 1rem;
  padding-bottom: 1rem;
  button {
    width: 300px;
  }
}

.progress-modal-content {
  display: inline-flex;
  width: 100%;
  padding: 2rem;
  justify-content: center;
  min-height: 25rem;
  height: 70vh;
}

.progress-modal-footer {
  display: flex;
  justify-content: center;
  height: 2rem;
  .slds-progress {
    margin: 1rem;
  }
  .progress-back, .progress-next{
    width: 5rem;
  }
}

#test-method-content {
  padding-top: 3rem;
}

#progress-indicator {
  max-width: 40%
}

.slds-modal__header {
  border-bottom-style: none;
  margin-top: 0;
}

.slds-icon_large {
  height: 4rem;
  width: 4rem;
}

label[for='contact-id-input'] {
  text-align: center;
  width: 35rem;
}

#contact-id-input {
  width: 35rem;
  margin-top: 5rem;
}

.slds-nav-vertical__item {
  a {
    padding-left: 0.5rem;
  }
  button {
    padding: 0 1rem 0 1rem;
  }
}

.list-nav-content {
  display: flex;
}

.nested-list {
  padding-left: 1rem;
}

.de-container {
  display: flex;
  .select-option-table {
    .slds-table_bordered {
      border: none;
      border-bottom: 1px solid #ddd;
    }
  }
  border: 1px solid #ddd;
  min-height: 20rem;
  height: 50vh;
  max-height: 25rem;
}

.slds-nav-vertical__item:focus-within:before {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.slds-nav-vertical__action:hover {
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(21,137,238,0.1);
}

.slds-nav-vertical__item:hover:before {
  background: none;
}

.slds-nav-vertical__item {
  button:hover {
    background: rgba(21,137,238,0.1);
  }
}

.slds-nav-vertical__action.slds-is-active {
  font-weight: bold;
}

.slds-split-view_container.slds-is-open{
  max-width: 16rem !important;
  min-width: 16rem !important;
}

.slds-split-view_container.slds-is-closed{
  max-width: 0;
  min-width: 0;
}

.slds-split-view_container article {
  width: 16rem !important;
}

.slds-split-view {
  background: none;
}

.slds-split-view__toggle-button {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-top: 0;
  border-bottom: 0;
}

.slds-table_header-fixed_container {
  border: 0;
}

.empty {
  padding: 1rem;
  text-align: center;
  font-style: italic;
}

#audience-selector-dropdown {
  padding-bottom: 1rem;
  .slds-dropdown {
    min-width: 12rem;
    max-width: 12rem;
  }
}

.select-option-table {
  height: 50vh;
  overflow: scroll;
  min-height: 15rem;
}

.de {
  overflow: scroll;
}

.de-container {
  .select-option-table {
    height: 100vh;
  }
}

thead > tr > th.slds-text-align_right {
  width: 1.5rem !important;
}

.slds-table_header-fixed_container, .slds-table--header-fixed_container {
  background: none;
}

.missing-category {
	.missing-category-heading {
		margin-bottom: 0.5rem;
		font-weight: 700;
	}
	.missing-category-message {
		text-align: left;
	}
}
.contacts-selected-count {
  text-align: right;
  height: 21px;
}

.count-over-limit {
  color: var(--slds-g-color-error-base-40,#ea001e);
}

#test-send {
  padding-bottom: 1rem;

  .contacts-selected-count {
    display: none;
  }

  .itemsOverMaxSelection {
    .contacts-selected-count {
      display: block;
    }

    .select-option-table {
      thead > tr > th > div {
        display: none;
      }
    }
  }

  .select-option-table {
    .slds-checkbox_faux {
      margin-top: -2px;
    }
  }
}


.progress-modal-test-results {
  width: 70%;
  .slds-timeline__item_success:before {
    background: #3ba755;
  }

  .slds-timeline__item_failure:before {
    background: red;
  }

  .slds-timeline__item_progress:before {
    background: #939393;
  }

  .slds-timeline__item_queued:before {
    background: #fcc003;
  }

  .slds-timeline__item_last:before {
    background: white;
  }

  .timeline-view-headers:hover {
    background-color: transparent;
  }

  .label-style-margin {
    margin: 0px;
  }

  .trigger-api-retry > svg {
    width: 0.8rem;
    height: 0.8rem;
    margin-left: 5px;
  }

  .pre-loader {
    margin-left: 10px;
  }

  .hideExpandButton {
    padding-left: 1.7rem;
  }

  .dummy-opacity {
    opacity: 0.3;
  }

  .slds-timeline__item_expandable .slds-media__figure .slds-button_icon {
    margin-right: 0;
  }

  .toggle-info-btn {
    padding-right: 0.7rem;
  }
}

#progress-indicator-tooltip-0, #progress-indicator-tooltip-1, #progress-indicator-tooltip-2, #progress-indicator-tooltip-3 {
  display: none;
}

.hide-visibility {
  visibility: hidden;
}
