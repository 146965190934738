$brand-primary-color: #1589ee;

#message-table {
	.slds-table {
		border: none;

		thead {
			tr {
				th:not(.slds-is-sorted):hover {
					&[data-sortdirection="ASC"] {
						.slds-icon_container.slds-icon-utility-arrowdown {
							display: inline-block;
						}

						.slds-icon_container.slds-icon-utility-arrowup {
							display: none;
						}
					}

					&[data-sortdirection="DESC"] {
						.slds-icon_container.slds-icon-utility-arrowup {
							display: inline-block;
						}

						.slds-icon_container.slds-icon-utility-arrowdown {
							display: none;
						}
					}

					a {
						background-color: white;
						color: currentColor;
					}
				}

				th[data-sortdirection="DESC"] {
					.slds-icon_container.slds-icon-utility-arrowdown {
						display: inline-block;
					}

					.slds-icon_container.slds-icon-utility-arrowup {
						display: none;
					}
				}

				th[data-sortdirection="ASC"] {
					.slds-icon_container.slds-icon-utility-arrowup {
						display: inline-block;
					}

					.slds-icon_container.slds-icon-utility-arrowdown {
						display: none;
					}
				}

				th {
					border-bottom: 1px solid #dddbda;
					position: sticky;
					z-index: 1000;
					top: 0px;

					div.slds-grid_vertical-align-center {
						height: 100%;
					}

					a {
						padding: 4px;

						&:focus {
							background-color: inherit;
						}
					}

					&:first-child {
						width: 25px;
					}
				}
			}
		}

		tbody {
			.loader {
				display: none;
			}

			tr {
				cursor: pointer;

				td {
					padding: 4px;

					&.selection-col {
						display: flex;
						height: 28px;
						align-items: center;
						justify-content: center;
						padding: 0px;

						span {
							display: none;
						}

						svg {
							margin-top: -2px;
							fill: $brand-primary-color;
						}
					}

					&.app {
						.slds-truncate {
							img {
								height: 18px;
								width: auto;
								max-width: 100%;
								border: none;
								margin-right: 2px;
								margin-top: -2.5px;
							}
						}
					}
				}

				&.selected {
					td {
						background-color: #f3f2f2;

						&.selection-col {
							span {
								display: inline-block;
							}
						}
					}
				}

				&#load-more-items-row {
					cursor: pointer;

					td {
						padding-top: 7px;
						padding-bottom: 7px;
						color: #006dcc;
					}
				}

				&#loader-row {
					cursor: default;

					td {
						padding-top: 8px;
					}

					.loader-row-container {
						cursor: default;
						display: flex;
						align-items: center;
						justify-content: center;

						.slds-spinner {
							top: 8px;
							margin-right: 10px;
						}

						span {
							display: inline-block;
							margin-top: -2px;
						}
					}

					&:hover {
						td {
							box-shadow: none;
							background: initial;
						}
					}
				}

				&.additional-row {
					cursor: default;

					td {
						text-align: center;
					}

					&:hover {
						td {
							box-shadow: none !important;
							background: initial;
						}
					}
				}

				&:first-of-type {
					td {
						border-top: none;
					}
				}

				&:last-child {
					td {
						border-bottom: none;
					}
				}
			}

			&.fetching {
				.loader {
					display: block;
				}

				tr {
					display: none;
				}
			}

			&.additional-fetching {
				tr {
					cursor: default;

					td {
						opacity: .5;
					}

					&#loader-row td {
						opacity: 1;
					}

					&#no-items-row {
						display: none;
					}

					&:not(.selected):hover {
						td {
							box-shadow: none !important;
							background: initial;
						}
					}

					&.selected:hover {
						td {
							box-shadow: none !important;
						}
					}
				}
			}
		}
	}
}
