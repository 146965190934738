#detail-view-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	padding-top: 0;

	.wrapper {
		width: 100%;
		height: 100%;
		overflow: auto;
		border: 1px solid #dddbda;
		border-radius: 0.25rem;

		.header {
			background-color: #f3f3f2;
			padding: 20px;

			.message-name {
				font-weight: bold;
			}

			.warning {
				font-style: italic;
			}
		}

		.previewer {
			padding: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			
			iframe {
				height: 550px;
				border: none;
			}
		}
	}
}