.combobox-component {
	position: relative;
}

.combobox-component .slds-combobox_container.read-only .slds-combobox__form-element .slds-icon_container {
	margin-top: -10px;
}

.combobox-component .slds-combobox_container.read-only .slds-dropdown {
	margin-top: -5px;
}

.combobox-component .slds-combobox__form-element {
	cursor: text;
}

.combobox-component .slds-combobox__form-element img {
	margin-top: -2px;
    margin-right: 2px;
    margin-left: -5px;
}

.combobox-component .slds-input:focus {
	outline: none;
}

.combobox-component .slds-input.has-icon {
	padding-left: 40px;
}

.combobox-component img {
	height: 20px;
	width: 20px;
}

.combobox-component li.disabled {
	cursor: not-allowed;
}
.combobox-component li > div.disabled {
	pointer-events: none;
}

.combobox-component .hidden {
	display: none !important;
}

.combobox-loader {
	position: absolute;
    top: 16px;
    left: 10px;
}
