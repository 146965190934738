// SASS Variables
$default-heading-color: #3e3e3c;
$default-subheading-color: #706e6b;
$default-label-font-size: 0.75rem;
$default-error-text-color: #c23934;

html,body {
	height: 100%;
	padding: 0;
	margin: 0;
	background-color: #ffffff;
}

#mount {
	height: 100%;

	.mobilepush-push-activity {
		height: 100%;
	}

	#slds-vertical-tabs-swap {
		overflow-x: hidden;
	}

	.base-hub-view {
		background: white;
	}

	.section-heading {
		font-size: 20px;
		display: block;
		margin-bottom: 5px;
		color: $default-heading-color;
	}

	.section-subheading {
		font-size: 14px;
		display: block;
		margin-bottom: 20px;
		font-style: italic;
		font-weight: 300;
		color: $default-subheading-color;
	}

	.container {
		margin-bottom: 2rem;
	}

	.container-heading {
		margin-bottom: .5rem;
		color: $default-subheading-color;
		font-weight: 300;
		text-transform: uppercase;
		display: inline-block;
	}

	.hidden {
		display: none;
	}

	.help-bubble {
		.slds-form-element {
			margin: 0;
		}

		.slds-popover__body a {
			color: white;
			text-decoration: underline;
		}
	}

	.hub-view-data {
		.slds-m-bottom_small:last-child {
			margin-bottom: 0;
		}
	}

	#push-message-datatable {
		padding: 0px 20px 20px 6px;
		// height: calc(100% - 32px);
		display: flex;
		justify-content: center;
		align-items: center;

		#message-table {
		    height: calc(100vh - 230px);
			width: 100%;
			overflow-y: auto;
			border: 1px solid #dddbda;

			.loader {
				height: calc(100vh - 300px);
			}
		}
	}

	.scm-container {
		#summary-hub-spokes {
			.hub-spoke {
				&[data-validation-state="error"] {
					.hub-spoke__description {
						color: $default-error-text-color;
					}
				}
			}
		}

		&[data-readonly="true"] {
			.spoke-button {
				display: none;
			}
		}
	}

	.link-btn {
		color: #006dcc;
		text-decoration: none;
		transition: color 0.1s linear;
		cursor: pointer;
	}
}

@import "~@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.css";
@import "summary.scss";
@import "wizard.scss";
@import "message-configuration.scss";
@import "delivery-options.scss";
@import "advanced-options.scss";
@import "summary-detail.scss";
@import "~@salesforce-mc/majik/dist/majik.css";
@import "~@salesforce-mc/majik-message-activity/dist/majik-message-activity.css";
