#advancedOptions {
	.slds-form-element {
		margin-bottom: 10px;

		.slds-input {
			width: 400px;
		}
	}

	.reporting-id-container {
		.container-heading {
			margin-right: 3px;
		}
		
		margin-bottom: 10px;
	}

	.add-custom-functionality-container {
		margin-top: 30px;

		.container-heading {
			margin-right: 3px;
		}

		.input-container {
			margin-bottom: 10px;

			.slds-form-element {
				margin-bottom: 0.5px;
			}

			.input-desc {
				color: #706e6b;
    			font-size: 0.75rem;
			}

			&:last-child {
				margin-bottom: 0px;
			}
		}
	}
}
